import React from 'react';
import s from './About.module.css'
import styles from "../../../styles/styles.module.css"
import classnames from "classnames";
import Icon from "@ant-design/icons";
import {ReactComponent as MapItem} from "../../../assets/images/publicPages/location.svg"
import hearts from "../../../assets/images/publicPages/hearts.svg"
import puzzles from "../../../assets/images/publicPages/about_pazzles.svg"
import flexibility from "../../../assets/images/publicPages/flexibility.svg"
import steps from "../../../assets/images/publicPages/steps.svg"
import phone from "../../../assets/images/publicPages/phone.svg"
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import Slider from "../../../components/Slider/Slider";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';
import {brandConfig} from '../../../config/config';

const About = () => {

    const navigate = useNavigate()

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={classnames(s.black_bg, s.block_short)}>
                    <p className={s.title}><span className={classnames(s.title, s.light_blue)}>{brandConfig.companyNameSimple.toUpperCase()}</span>, a dynamic &
                        innovative finance institution</p>
                    <div className={s.flex}>
                        <Icon className={s.icon_white} component={() => <MapItem/>} rev={undefined}/>
                        <span className={s.located}>located in Ontario, Canada</span>
                    </div>
                </div>

                <div className={s.about_cards}>
                    <div className={classnames(s.block, s.blue_bg)}>
                        <div className={s.about_card_title}>
                            About Us
                        </div>
                        <span>
                        Founded with a deep-rooted passion for transforming the financial industry, our company was born
                        from the vision of our experienced director, who recognized the need to address loopholes within
                        the sector. With extensive expertise gained from years of working in finance institutions, our
                        founder embarked on a dedicated journey to develop {brandConfig.companyNameSimple} into a trusted name in the industry.
                    </span>
                    </div>
                    <div className={classnames(s.block, s.gray_bg)}>
                        <div className={s.block_title}>
                            Meticulous attention to detail
                            and our unwavering commitment to customer satisfaction
                        </div>
                        <span>
                            Our team is driven by a shared passion for delivering exceptional service, ensuring that every
                            interaction with us exceeds your expectations. With a personalized approach, we tailor our
                            financial solutions to your unique requirements, empowering you to reach new heights and achieve
                            your financial goals.
                        </span>
                        <div className={s.card_button_wrapper}>
                            <div className={s.card_button_block}>
                                <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.reverse_cards_block}>
                    <div className={s.card_revert}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Relationships that matter
                            </div>
                            <span>
                            At {brandConfig.companyNameSimple}, we believe in building genuine relationships with our clients. We genuinely
                            want to get to know you and understand your needs. Unlike other finance institutions where
                            you may feel like just another customer, we prioritize meaningful interactions and personalized
                            service that goes beyond the surface.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={hearts} alt=""/>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Long-lasting partnership
                            </div>
                            <span>
                            {brandConfig.companyNameSimple} is owned and operated by a dedicated team that remains unchanged since our inception.
                            This commitment to stability ensures that you benefit from a consistent and reliable experience.
                            Unlike larger institutions that frequently undergo mergers and acquisitions, resulting in new
                            terms and unfamiliar faces, we provide a sense of continuity and a deep understanding of your
                            unique story.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={puzzles} alt=""/>
                        </div>
                    </div>

                    <div className={s.card_revert}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Flexibility
                                & convenience
                            </div>
                            <span>
                            We understand that your time is valuable, which is why we offer easy access to decision-makers
                            and maintain consistency by assigning you a dedicated team throughout your journey with us.
                            We believe in streamlining processes and providing you with the convenience you deserve,
                            allowing you to focus on what matters most to you.
                        </span>
                        </div>
                        <div className={s.flexibility_image_block}>
                            <img className={s.img} src={flexibility} alt=""/>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Unwavering commitment
                            </div>
                            <span>
                            When you choose {brandConfig.companyNameSimple}, we stand by you every step of the way.
                            We are committed to nurturing long-term relationships with our customers, providing unwavering
                            support and assistance
                            throughout your experience with us. Your success is our success, and we take pride in being
                            a reliable partner on your financial journey.
                        </span>
                            <div className={s.card_button_wrapper}>
                                <div className={s.card_button_block}>
                                    <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                                </div>
                            </div>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={steps} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={classnames(s.flex, s.slider_wrapper)}>
                    <div className={s.phone_image_block}>
                        <img src={phone} alt=""/>
                    </div>
                    <div className={s.carousel_wrapper}>
                        <Slider/>
                    </div>
                </div>

                <div className={s.contact_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
        </motion.div>
    );
};

export default About;
