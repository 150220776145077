import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppDispatch } from "../../hooks/redux";
import {
    CurrencyHelper,
    getBusinessPaymentType,
    getItemsPaymentType,
} from "../BankTransferFormPersonal/FormHelpers/FormHelpers";
import { getValidationSchema as getIndividualValidationSchema } from "../BankTransferFormPersonal/FormHelpers/FormHelpers";
import { getValidationSchema } from "./FormHelpers/FormHelpers";
import { setPaymentRegionFilter } from "../../store/reducers/TransactionsSlice/TransactionsSlice";
import { selectAccountsByFilter } from "../../store/reducers/TransactionsSlice/selector";
import { useSelector } from "react-redux";
import GenerateTransactionForm from "../GenerateTransactionForm/GenerateTransactionForm";
import s from "./BankTransferFormBusiness.module.css";
import { myDate } from "../../helpers/CalculateDate";
import { phonecodes } from "../../constants/StaticData";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>;
    setTransferData?: Dispatch<SetStateAction<any>>;
    id?: string | number;
};

const BankTransferFormBusiness = (props: PropsType) => {

    const {
        id,
        setCurrent,
        setTransferData
    } = props;

    const [paymentType, setPaymentType] = useState("SHA");
    const [paymentRegion, setPaymentRegion] = useState("");
    const [currency, setCurrency] = useState("");
    const dispatch = useAppDispatch();

    const accountsList = useSelector(selectAccountsByFilter);

    const [date, setDate] = useState({
        from:
            myDate.getFullYear() +
            "-" +
            (myDate.getMonth() + 1 < 10
                ? ("0" + (myDate.getMonth() + 1)).slice(-2)
                : myDate.getMonth() + 1) +
            "-" +
            myDate.getDate(),
    });

    // eslint-disable-next-line 
    const [code, setCode] = useState({
        co: "ad",
        ph: "376",
        na: "Andorra",
    });

    const [paymentTypeByRegion, setPaymentTypeByRegion] = useState("");

    useEffect(() => {
        dispatch(setPaymentRegionFilter(paymentRegion));
        return () => {
            localStorage.removeItem("transfer-form");
        };
    }, [paymentRegion, dispatch]);

    const [selectedOptionRecipientType, setSelectedOptionRecipientType] =
        useState("BUSINESS");
    const findRelatedValidationSchema = () => {
        if (selectedOptionRecipientType === "BUSINESS") {
            return getValidationSchema(paymentTypeByRegion, paymentRegion);
        } else {
            return getIndividualValidationSchema(
                paymentTypeByRegion,
                paymentRegion
            );
        }
    };

    const getCountryCode = (countryName: string) => {
        const country = phonecodes.find((entry) => entry.na === countryName);
        return country?.co;
    };

    return (
        <div>
            <motion.div
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <GenerateTransactionForm
                    s={s}
                    id={id}
                    accountsList={accountsList}
                    selectedOptionRecipientType={selectedOptionRecipientType}
                    findRelatedValidationSchema={findRelatedValidationSchema}
                    currency={currency}
                    paymentType={paymentType}
                    initialValues={{
                        payerAccount: "",
                        paymentRegions: "",
                        paymentType: "",
                        paymentTypeByRegionForm: "",
                        name: "",
                        firstName: "",
                        lastName: "",
                        middleName: "",
                        fullName: "",
                        bankCode: {
                            name: "",
                            value: "",
                        },
                        bankAccount: "",
                        bankAccountType: {
                            name: "",
                            value: "",
                        },
                        recipientsAddress: "",
                        recipientsCity: "",
                        recipientsZipCode: "",
                        recipientsCountry: "",
                        recipientsAccountNumber: "",
                        recipientsSwiftBic: "",
                        recipientsIban: "",
                        recipientsBankCountry: "",
                        recipientsBankAddress: "",
                        recipientsBankZipCode: "",
                        recipientsBankCity: "",
                        recipientsBankState: "",
                        recipientsBank: "",
                        email: "",
                        emailLanguage: "EN",
                        paymentDate: date.from,
                        transferCurrency: "USD",
                        transferReason: {
                            name: "",
                            value: "",
                        },
                        routingNumber: "",
                        street: "",
                        city: "",
                        postalCode: "",
                        phoneNumber: "",
                        purpose: "",
                        reference: "",
                        templateName: "",
                        amount: "",
                        type: "international",
                        relationshipToSender: "",
                        legalEntityType: {
                            name: "",
                            value: "",
                        },
                        natureOfBusiness: {
                            name: "",
                            value: "",
                        },
                        registrationNumber: "",
                        institutionNumber: "",
                        transitNumber: "",
                        uniqueId: "",
                        sortCode: "",
                        beneficiaryName: "",
                        beneficiaryEmail: "",
                        securityQuestion: "",
                        securityAnswer: "",
                        payeeName: "",
                        payeeCode: "",
                        payeeAccountNumber: "",

                        recipientType: selectedOptionRecipientType,

                        recipientCountry: {
                            // from individual
                            name: "",
                            value: "",
                        },
                        mobileProvider: {
                            name: "",
                            value: "",
                        },
                        identityCardId: "",
                        identityCardType: {
                            name: "",
                            value: "",
                        },
                        cashProvider: "",
                        branchCode: {
                            name: "",
                            value: "",
                        },
                        branchCodeTED: "",
                        pixKeyType: {
                            name: "",
                            value: "",
                        },
                        pixKeyValue: "",
                        senderIdType: {
                            name: "",
                            value: "",
                        },
                        senderIdNumber: "",
                        invoice: "",
                    }}
                    CurrencyHelper={CurrencyHelper}
                    setPaymentType={setPaymentType}
                    setCurrent={setCurrent}
                    setTransferData={setTransferData}
                    setPaymentRegion={setPaymentRegion}
                    setCurrency={setCurrency}
                    setSelectedOptionRecipientType={
                        setSelectedOptionRecipientType
                    }
                    getItemsPaymentType={getItemsPaymentType}
                    getBusinessPaymentType={getBusinessPaymentType}
                    setPaymentTypeByRegion={setPaymentTypeByRegion}
                    setCode={setCode}
                    generateType="businessTransferForm"
                    setDate={setDate}
                    date={date}
                    onSubmit={(values) => {
                        let tempData = {
                            ...values,
                            recipientsCountry: ((values.paymentRegions).toUpperCase() !== "PAN AFRICA") ? getCountryCode(values.recipientsCountry) : values.recipientsCountry,
                            recipientsBankCountry: ((values.paymentRegions).toUpperCase() !== "PAN AFRICA") ? getCountryCode(values.recipientsBankCountry) : values.recipientsBankCountry,
                            phoneNumber:
                                values.phoneNumber !== ""
                                    ? values.phoneNumber
                                    : "",
                            transferCurrency: currency,
                            paymentDate: date.from,
                        };

                        if (
                            values.paymentRegions ===
                            "SWIFT (International Transfer)"
                        ) {
                            tempData = { ...tempData, paymentType };
                        }

                        setTransferData &&
                            setTransferData(tempData);
                        setCurrent && setCurrent(1);
                    }}
                />
            </motion.div>
        </div>
    );
};

export default BankTransferFormBusiness;
