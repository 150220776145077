import React, {useState} from 'react';
import {motion} from 'framer-motion';
import s from "./RegistrationSteps.module.css"
import {NavLink} from "react-router-dom";
import quest from "../../assets/images/common/quest.svg";
import SignUp from "./SignUp/SignUp";
import RegistrationAutentication from "./RegistrationAutentication/RegistrationAutentication";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
import brandConfig, { brandTheme } from '../../config/config';
import euroIcon from "../../assets/images/registerPage/euro.svg";
import pointerIcon from "../../assets/images/registerPage/pointer.svg";
import bankingIcon from "../../assets/images/registerPage/banking.svg";
import fiatIcon from "../../assets/images/registerPage/fiat.svg";
import worldVector from "../../assets/images/registerPage/world.svg";

const RegistrationSteps = () => {

    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false)

    const steps = [
        {
            content: <SignUp setCurrent={setCurrent}
                             current={current}
            />
        },
        {
            content: <RegistrationAutentication/>
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.wrapper}>
                <CustomModalContactContainer setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>
                <div className={s.login_wrapper_content_left}>
                    <div className={s.left_content}>
                        <div className={s.left_content_title}>{brandConfig.companyNameSimple}</div>
                    </div>
                    <div className={s.logo_block}>
                        <NavLink to={"/"}>
                            <img src={brandTheme.brandIcon} alt={brandTheme.brandLogoAlt} width={brandTheme.brandLogoWidth} height={brandTheme.brandLogoHeight} />
                        </NavLink>
                    </div>
                </div>
                <div className={s.login_wrapper_content_right}>
                    <div className={s.quest_block}>
                        <img src={quest} alt="" onClick={() => setIsModalOpen(true)}/>
                    </div>
                    <div>{steps[current].content}</div>
                </div>
            </div>

        </motion.div>
    );
};

export default RegistrationSteps;
