import React from 'react';
import styles from "../../../styles/styles.module.css";
import s from "./Contacts.module.css";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import contact_us from "../../../assets/images/publicPages/contact_us/contact_us.png"
import map from "../../../assets/images/publicPages/contact_us/location.svg"
import phone from "../../../assets/images/publicPages/contact_us/call.svg"
import mail from "../../../assets/images/publicPages/contact_us/sms.svg"
import classnames from "classnames";
import {brandConfig} from '../../../config/config';

const Contacts = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>

                <div className={s.top_block}>
                    <div className={s.contacts_block}>
                        <div className={s.title}>
                            Contacts
                        </div>
                        <div className={s.row}>
                            <img src={map} alt=""/>
                            <span className={s.address_text}>
                                4 Robert Speck Parkway, Mississauga ON L4Z 1S1, Canada
                            </span>
                        </div>
                        <div className={s.row}>
                            <img src={phone} alt=""/>
                            <span className={s.address_text}>
                                +1 (888) 879-8570 <br/>
                                +1 (888) 792-5272
                            </span>
                        </div>
                        <div className={s.row}>
                            <img src={mail} alt=""/>
                            <a href={`mailto:${brandConfig.companyInfoEmailAddress}`}>
                                <span className={classnames(s.address_text, s.link)}>
                                    {brandConfig.companyInfoEmailAddress}
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className={s.img_block}>
                        <img src={contact_us} alt=""/>
                    </div>
                </div>

                <div className={s.contact_us_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
    );
};

export default Contacts;
