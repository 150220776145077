import React, {Dispatch, SetStateAction, useState} from 'react';
import {useAppDispatch} from "../../hooks/redux";
import {useFormik} from "formik";
import {motion} from "framer-motion";
import s from "./ForgotPassword.module.css";
import MyInput from "../ui/MyInput/MyInput";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Link} from "react-router-dom";
import {resetPasswordThunk} from "../../store/reducers/ActionCreators";
import CustomModal from "../ui/CustomModal/CustomModal";
import attention from "../../assets/images/common/attention.svg";
import { brandConfig } from "../../config/config";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setDisabled } from '../../store/reducers/UserSlice';

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
}

const ForgotPassword = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [is2FaShow, setIs2FaShow] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAuthSuccess, setIsAuthSuccess] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const formikForgot = useFormik({
        initialValues: {
            email: '',
            twoFaCode: ''
        },
        onSubmit: async values => {
            console.log('submitted')
            if (!executeRecaptcha) {
                console.log('reCAPTCHA is not ready');
                return;
            }
            dispatch(setDisabled(true));
            const recaptchaToken = await executeRecaptcha('forgotpass');
            const finalValues = { ...values, recaptchaToken };
            dispatch(setDisabled(true));
            dispatch(resetPasswordThunk(finalValues))
                .then((res) => {
                    if (res.data === 'Change password link has been sent to email.') {
                        setIsModalOpen(true)
                    }
                })
                .catch((error) => {
                    if (error.response.data === "Need 2Fa Code") {
                        setIs2FaShow(true)
                    }

                    setIsAuthSuccess(false)
                    setErrorMessage(error.response.data)

            }).finally(() => dispatch(setDisabled(false)));
        }
    })

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <div className={s.flex}>
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.modal_subtitle}>
                        An email with a link to reset your password has been sent to your email address.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.sign_in_wrapper}>
                <form onSubmit={formikForgot.handleSubmit}>
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            Forgot password
                        </div>
                        {
                            !isAuthSuccess &&
                            <div className={s.error_block}>
                                <img src={attention} alt=""/>
                                <div>
                                    {errorMessage}
                                </div>
                            </div>
                        }
                        <div className={s.subtitle}>
                            Enter your {brandConfig.companyName} account email address.
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Email address
                            </div>
                            <MyInput id="email"
                                     name="email"
                                     isError={formikForgot.errors.email}
                                     value={formikForgot.values.email}
                                     onChange={formikForgot.handleChange}
                            />
                            {formikForgot.errors.email &&
                                <div className={s.error_message}>invalid email address</div>}
                        </div>

                        {
                            is2FaShow
                                ? <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        2FA Token from Google Authenticator
                                    </div>
                                    <MyInput
                                        id="twoFaCode"
                                        name="twoFaCode"
                                        value={formikForgot.values.twoFaCode}
                                        onChange={formikForgot.handleChange}
                                        placeholder={"6 digits"}
                                    />
                                </div>
                                : <></>
                        }

                        <div className={s.btn_block}>
                            <MyBtn
                                title={"Continue"}
                                type={"submit"}
                            />
                        </div>
                        {brandConfig.companyComplianceEmailAddress &&
                            <div className={s.forget_credentials}>
                                If you forget all your credentials from your
                                account, please contact our Support via&nbsp;
                                <a href={`mailto:${brandConfig.companyComplianceEmailAddress}`}> {brandConfig.companyComplianceEmailAddress}</a>
                            </div>
                        }

                    </div>
                    <div className={s.dont_have_account}>
                        <div>
                            Back to
                        </div>
                        <Link className={s.link} to={"/login"} onClick={() => props.setCurrent(0)}>Sign In</Link>
                    </div>
                </form>
            </div>

        </motion.div>

    );
};

export default ForgotPassword;
